var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row position-relative",class:_vm.RoutePage == 'QuizFinal' ? 'm-0 p-0 w-100 big-div-quiz' : 'mt-0 mt-sm-5 w-85 big-div',style:(_vm.getPageDesign())},[_c('div',{staticClass:"col-12"},[_c('div',_vm._l((_vm.pageDesign.blocksArray),function(pro,ind){return _c('div',{key:'preview' + ind,staticClass:"mt-4",attrs:{"data-aos":_vm.animationType,"data-aos-duration":_vm.getAnimationSpeedinMillis,"data-aos-delay":_vm.AOSAnimationDelay(ind)}},[_c('div',{staticClass:"content",staticStyle:{"position":"relative"},style:(_vm.getHeightWidth(pro.style)),attrs:{"id":"preview"}},[(pro.type == 'text')?_c('div',{style:(_vm.getColorCss(pro.style) +
              _vm.getPaddingCss(pro.style) +
              _vm.getMarginCss(pro.style) +
              _vm.getColorWithOpacity(pro.style) +
              _vm.getTextRotate(pro.style) +
              _vm.getLineHeight(pro.style) +
              _vm.getTextAlign(pro.style) +
              _vm.getFontStyle(pro.style,_vm.isDesktopView)),domProps:{"innerHTML":_vm._s(pro.content)}}):_c('div',{style:('display:flex;justify-content:' + pro.style.position + ';')},[_c('button',{style:(_vm.getButtonCss(pro.style,_vm.isDesktopView)),on:{"click":_vm.RestartQuiz}},[_c('span',{style:({
                  opacity: `${pro.style.textOpacity}%`,
                  fontSize: `${pro.style.fontSize}px`,
                })},[_vm._v(" "+_vm._s(pro.text)+" ")]),_vm._v(" "+_vm._s(pro.style.alignItems)+" ")])])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }